body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -moz-font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

/* @font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff)
      format("woff");
} */

input,
select,
option {
  font-size: 16px !important;
  color: black;
  font-family: inherit;
}

::-webkit-input-placeholder {
  font-size: 16px !important;
}
::-moz-placeholder {
  font-size: 16px !important;
} /* firefox 19+ */
:-ms-input-placeholder {
  font-size: 16px !important;
} /* ie */
input:-moz-placeholder {
  font-size: 16px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#ccnumber input,
#ccexp input {
  border-width: 0px;
  border-left: 1px #adb7c0 solid;
  height: 100%;
  font-size: 16px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0;
  padding-left: 5px;
}
