.page {
  /* background-image: url("https://app-membership-signup-web-dev.wwdb.com/images/login-background.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  max-width: 600px;
  margin: auto;
}

.infoModalContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1.5em;
}

.bold {
  font-weight: 400;
}

.alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  gap: 15px;
  margin: 20px 10px 0 10px;
}

.altAlertColor {
  color: #243746;
}

.helpRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-style: italic;
  gap: 5px;
}

.alertRow {
  width: 100%;
}

.divider {
  width: 100%;
  height: 1px;
  border-top: 1px solid #91b6bb;
}

.inviteError {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #adb7c0;
  font-family: "Open Sans";
  text-align: center;
}

.container {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  width: 100%
}

.container > div {
  width: 100%;
}

.card {
  background-color: hsla(0, 0%, 100%, 0.53);
  padding-bottom: 1em;
  padding-top: 24px;
}

.logo {
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #adb7c0;
  font-size: 24px;
  font-weight: 300;
  cursor: pointer;
}

.iboNumber {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
}

.input,
.input2 {
  padding: 6px 12px;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  margin-left: 10px;
  height: 41px;
}

.input {
  width: 95%;
}

.input2 {
  width: calc(100% - 82.6px);
}

.input::placeholder,
.input2::placeholder {
  color: #adb7c0;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 300;
}

.label {
  margin: 9px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2em;
  color: #243746;
}
.label2 {
  margin: 9px;
  margin-top: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2em;
  color: #243746;
}

.link {
  cursor: pointer;
  font-weight: 400;
  text-decoration: underline;
  display: inline-block;
}

.btnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 12px;
  margin-bottom: 25px;
}

.center {
  display: flex;
}

.nextBtn {
  background-color: #adb7c0 !important;
  height: 32px;
  width: 96px;
}

.cancelBtn {
  background-color: #999999;
  border-color: #243746;
}

.inputRow {
  display: flex;
  align-items: center;
  width: 100%;
}

.help {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
  color: black;
  cursor: pointer;
}

.message,
.footer {
  color: #777;
}
.message {
  display: flex;
  justify-content: center;
  font-size: 13px;
  margin-top: 24px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}


@media (max-width: 600px) {
  .footer {
    margin-bottom: 43px;
  }
}
