.input {
  display: flex;
}

.dropdownContainer {
  position: relative;
}

.dropdownContainer:focus {
  border-radius: 6px;
}

.dropdownOptionsContainer {
  position: absolute;
  background-color: white;
  width: calc(100% - 10px);
  border: 1px solid #adb7c0;
  border-radius: 6px;
  transform: translateY(-1px);
  font-size: 16px;
  box-shadow: 1px black;
  box-shadow: 0 0 5px 1px #d2d2d2;
  padding-right: 10px;
  z-index: 999;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdownOptions {
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
}

.dropdownOptions:hover {
  background-color: #ccc;
}

.inputBox {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border: 1px solid #adb7c0;
  border-radius: 6px;
  appearance: none;
  width: calc(100% - 10px);
  border-radius: 6px;
}

.sort {
  height: 20px;
  width: 20px;
  color: #999999;
  position: relative;
  bottom: 0;
  right: 0;
}

.inputBox {
  background-image: url("../../Images/sort-solid.svg");
  background-position: calc(100% - 10px);
  background-size: 13px 13px;
  background-repeat: no-repeat;
  background-color: white;
}

.inputBox:focus {
  outline: none;
}
