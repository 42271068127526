.receipt {
  padding-left: 13px;
  padding-right: 13px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  border-top: 1px solid #adb7c0;
  color: #22252e;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 15px;
}

.row {
  display: flex;
}

.row2 {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-top: 15px;
  border-bottom: 1px solid #91b6bb;
  padding-bottom: 10px;
}

.row3 {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 10px;
}

.label {
  width: 125px;
  font-size: 14px;
  color: #91b6bb;
}

.value {
  font-size: 14px;
}

.completed {
  color: #91b6bb;
  border-top: 1px solid #91b6bb;
  border-bottom: 1px solid #91b6bb;
  display: flex;
  justify-content: center;
  font-size: 21px;
  font-weight: 300;
  padding: 15px;
  margin-top: 15px;
}

.items {
  font-size: 18px;
  font-weight: 300;
  margin-top: 15px;
}

.total {
  font-weight: normal;
}

.controlsContainer {
  border-top: 1px solid #91b6bb;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.controls {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.info {
  display: flex;
  justify-content: center;
  font-size: 11px;
}

.appears {
  display: flex;
  justify-content: center;
  font-size: 10px;
  color: #22252e;
  margin-top: 6px;
}

.print {
  background-color: #39b54a;
  border-color: #39b54a;
  margin-right: 10px;
}

/* .printer {
  margin-right: 5px;
} */

.subTotalContainer {
  font-size: 13px;
  color: #243746;
}

.subTotalAmount {
  font-size: 15px;
  font-weight: 400;
}

.totalContainer {
  margin-top: 14px;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 300;
  color: #000000;
}

.totalAmount {
  font-size: 15px;
  font-weight: 600;
  color: #243746;
}

.billingAddressContainer {
  margin-top: 12px;
  margin-bottom: 12px;
  border-top: 1px solid #91b6bb;
  color: #243746;
  font-size: 12px;
}

.billingAddressHeader {
  color: #243746;
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.billingAddress {
  color: #243746;
  font-size: 14px;
}

.paymentMethodContainer {
  margin-top: 12px;
  margin-bottom: 12px;
  border-top: 1px solid #91b6bb;
  color: #243746;
  font-size: 12px;
}

.paymentMethodHeader {
  color: #243746;
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.paymentMethod {
  display: flex;
  align-items: center;
}

.paymentMethodIcon {
  display: inline-block;
  font-size: 15px;
}

.paymentMethodBigIcon {
  font-size: 30px;
}

.paymentMethodLastFour {
  display: inline-block;
  font-size: 15px;
  margin-left: 20px;
}

@media (min-width: 768px) {
  /* .row3 {
    margin-right: 400px;
    display: flex;
    justify-content: center;
  } */
  .total {
    margin-left: 25px;
  }
  .label {
    width: 125px;
    color: #91b6bb;
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
  }
}

@media print {
  .controls {
    display: none;
  }
}
