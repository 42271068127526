.input {
  display: flex;
}

.inputBox {
  font-size: 13px;
  padding: 10px;
  border: 1px solid #adb7c0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  appearance: none;
  flex: 2;
}

.noLabel {
  border-radius: 6px;
}

.inputLabel {
  display: inline-block;
  color: #415364;
  background-color: #e9ecef;
  font-size: 13px;
  padding: 10px;
  border: 1px solid #adb7c0;
  border-right: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  appearance: none;
  width: 106px;
}

.inputLabel2 {
  display: flex;
  justify-content: center;
  color: #415364;
  background-color: #e9ecef;
  font-size: 13px;
  padding: 5px 25px;
  padding-top: 7px;
  border: 1px solid #adb7c0;
  border-right: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  appearance: none;
  width: 5%;
}

.iconClass {
  height: 30px;
}

input.inputBox:focus {
  outline: none;
}

input.inputBox::placeholder {
  color: #adb7c0;
}
