.header {
  font-size: 18px;
  font-weight: 300;
  color: #243746;
  margin-bottom: 3px;
}

.info {
  font-size: 12px;
  font-weight: 300;
  color: black;
  margin-bottom: 10px;
}
