.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 600px;
  margin: auto;
}

.basePage,
.linkPage {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
}

.baseHeader {
  color: #243746;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  margin-top: 20px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 12px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 18px;
}

.divider {
  display: flex;
  margin: auto;
  border-bottom: 1px solid #91b6bb;
  width: 95%;
  margin-bottom: 6px;
}

.linkBtn {
  background-color: #91b6bb !important;
}

.linkPageHeader {
  display: flex;
  align-items: center;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyLinkBtn {
  background-color: #243746;
  margin-top: 24px;
}

.linkPageHeaderText {
  color: #adb7c0;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  margin: auto;
}

.linkPageSubheaderText {
  color: #243746;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  margin: 12px;
}

.inviteLink {
  align-self: center;
  overflow: hidden;
  color: #1a85f8;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 300;
  text-decoration-line: underline;
  max-width: 90%;
}

.copyToast {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  margin-top: 100%;
  margin-left: auto;
  margin-right: auto;
  transition: opacity ease-in-out 100ms;
}
