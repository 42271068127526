.container {
    padding-top: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid #B4CDD0;
    font-size: 15px;
    color: #22252E;
}

.row {
    display: flex;
    justify-content: space-between;
}

.info {
    font-size: 11px;
}

.amount{
    font-weight: 600;
}