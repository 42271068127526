.btnColor {
  background-color: #91b6bb !important;
  border-color: #91b6bb !important;
}

.btnColor2 {
  color: black !important;
  background-color: white !important;
  border-color: black !important;
}

.btnColor3 {
  background-color: #415364;
}

.backgroundColor {
  background-color: #f4f4f4;
}

h3 {
  font-family: "Open Sans";
  font-size: 24px;
  color: #adb7c0;
  font-weight: 300;
}

.modalOverlay {
  background-color: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  z-index: 2;
}

.modalOverlay.show {
  opacity: 1;
  visibility: visible;
}

.container {
  padding: 1em;
  margin: 0 auto;
  font-size: 13px;
  font-weight: lighter;
  color: #243746;
  font-family: "Open Sans";
}

.btn {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.info {
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .container {
    width: 75%;
  }
}

@media (min-width: 600px) {
  .addressContainer {
    max-width: 600px;
    margin: auto;
    right: 0;
    left: 0;
  }
}
