.modalOverlay {
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  pointer-events: none;
  width: 100%;
  z-index: 2;
}

.modalOverlay.show {
  opacity: 1;
  visibility: visible;
}

.container {
  padding: 1em;
  margin: 0 auto;
  font-size: 13px;
  font-weight: lighter;
  color: #243746;
}

.btn {
  display: flex;
  justify-content: center;
}

.btnColor {
  background-color: #22252e;
}

@media (min-width: 768px) {
  .container {
    width: 75%;
  }
}

.info {
  margin-bottom: 5px;
}
