.container {
  background-color: rgba(36, 55, 70);
  padding: 1em;
  opacity: 0;
  max-width: 100%;
  margin: 0 auto;
  font-size: 13px;
  font-weight: lighter;
  color: white;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  height: 120px;
}

.container.show {
  opacity: 1;
  visibility: visible;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  justify-content: space-between;
  height: 100%;
}

.btnColor {
  background-color: #39b54a !important;
  margin-left: 5px;
}

.btnColor2 {
  background-color: #adb7c0;
  margin-right: 5px;
}

.btnGroup {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.infoGroup {
  display: flex;
  justify-content: space-between;
}

.label {
  color: #91b6bb;
}

@media (max-width: 600px) {
  .btnGroup {
    margin-bottom: 43px;
  }

  .container {
    height: 163px;
  }
}
