.card {
  margin: 14px auto 0px auto;
  height: fit-content;
  max-width: 500px;
  background-color: #91b6bb;
  border-radius: 11px;
  padding-top: 14px;
  padding-bottom: 14px;
  color: white;
  box-shadow: 0 8px 12px -10px black;
  /* padding: 14px 10px 0px 10px; */
}

.line {
  border-bottom: 1px solid white;
  width: 35%;
  margin: 15px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 18px;
}

.row2 {
  display: flex;
  justify-content: center;
  font-size: 13px;
  padding: 0px 10px;
}

.features {
  background-color: white;
  color: black;
  border-radius: 26px;
  font-size: 14px;
  margin: 15px 18px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  padding: 3px 0px;
}

.features a {
  color: black;
  text-decoration: none;
}

.img {
  margin-right: 6px;
}

.kate {
  margin-bottom: 6px;
}
