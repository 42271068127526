.button {
  background-color: #91b6bb;
  padding: 0 0.8em;
  border-radius: 4px;
  border: 1px solid #91b6bb;
  color: white;
  font-size: 14px !important;
  line-height: 30px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  cursor: pointer;
}

.button2 {
  background-color: white;
  padding: 0 0.8em;
  border-radius: 4px;
  border: 1px solid #91b6bb;
  color: #91b6bb;
  line-height: 30px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  cursor: pointer;
  height: 32px !important;
  width: 63px !important;
}