.flex {
    display: flex;
    justify-content: space-between;
}

.createAccount {
    font-size: 24px;
    font-weight: 300;
    color: #ADB7C0;
}

.ibo {
    font-size: 14px;
    color: black;
    display: flex;
    align-items: flex-end;
}

.membershipChoice {
    font-size: 16px;
    color: black;
}

.iboNumber {
    font-size: 14px;
    color: #91B6BB;
}