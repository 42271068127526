.imgCenter {
  display: flex;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 12px;
}

.imgInfo {
  color: #22252e;
  font-size: 13px;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
}

.imgInfo1 {
  color: #243746;
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
}

.download {
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
}

.download img {
  cursor: pointer;
}

.appStore {
  margin-right: 12px;
}

.borderBottom {
  border-bottom: 1px solid #adb7c0;
}

.claim {
  font-size: 13px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.backgroundOne {
  background-color: #39b54a !important;
  border-color: #39b54a !important;
}

.backgroundTwo {
  background-color: #15b0f8 !important;
  border-color: #15b0f8 !important;
}

@media (min-width: 600px) {
  .container {
    max-width: 600px;
    margin: auto;
  }
}

@media print {
  .noPrint {
    display: none;
  }
}
