.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  margin: 13px 40px;
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  color: #22252e;
}

.container img {
  width: 222.259px;
  height: 45.29px;
  margin-left: auto;
  margin-right: auto;
}
