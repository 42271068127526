.container {
  padding-top: 6px;
  padding-bottom: 10px;
  border-bottom: 1px solid #728c9f;
  font-size: 12px;
}

.row {
  display: flex;
}

.row2 {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.gold {
  color: var(--Authenticity---9E703C, #9e703c);
}

.header {
  font-weight: 600;
  color: var(--Authenticity---9E703C, #9e703c);
}

.subRow {
  display: flex;
  justify-content: space-between;
  color: #415364;
}

.imgBox {
  width: 61px;
  margin-right: 12px;
}

.img {
  margin-left: 12px;
}

.prices {
  color: #91b6bb;
  flex: 2;
}

.type {
  color: var(--Strength---243746, #243746);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.due {
  margin-right: 12px;
}

.digital {
  color: black;
  font-weight: bold;
}

.digital2 {
  color: black;
  font-weight: bold;
  display: none;
}

.display2 {
  display: none;
}

@media (min-width: 768px) {
  .digital {
    color: black;
    font-weight: bold;
    display: none;
  }

  .digital2 {
    color: black;
    font-weight: bold;
    display: contents;
  }

  .row2 {
    justify-content: start;
  }
  .display2 {
    display: contents;
  }
  .display {
    display: none;
  }

  .due {
    margin-left: 60px;
    margin-right: 60px;
  }
}
