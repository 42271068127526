.container {
  padding: 12px;
}

.required {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 18px;
  margin-bottom: 25px;
}

.checkBox {
  flex: 2;
  display: flex;
  justify-content: center;
  font-size: 13px;
}

.input {
  margin-bottom: 6px;
}

.header {
  margin-bottom: 18px;
}

.status {
  display: flex;
}

.fill1 {
  border: 3px solid #243746;
  border-radius: 6px;
  flex: 2;
  z-index: 2;
}

.fill2 {
  border: 3px solid #adb7c0;
  flex: 5;
  margin-left: -2px;
  border-radius: 6px;
}

.backgroundTwo {
  background-color: #b2bac0 !important;
}

.controls {
  display: flex;
  margin: 20px;
}

.next {
  flex-grow: 2;
  display: flex;
  justify-content: center;
}

.footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.link {
  text-decoration: none;
}

/* CheckBox */
.containerBox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  appearance: none;
  outline: none;
  background-color: #fff;
  border: 1px solid #243746;
  border-radius: 2.5px;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}

.checkbox:checked {
  background-color: #91b6bb;
  border: 1px solid #243746;
}

.checkbox:checked + .icon {
  visibility: visible;
}

.icon {
  visibility: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.checkbox:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkbox:disabled + .icon {
  opacity: 0.5;
}

.checkBoxInfo {
  margin-left: 10px;
}

.errorText {
  font-family: "Open Sans", sans-serif;
  color: #ff0000;
  margin-bottom: 5px;
}

.error input {
  border: 1px solid #ff0000;
}

@media (min-width: 600px) {
  .footer,
  .container {
    max-width: 600px;
    margin: auto;
    right: 0;
    left: 0;
  }
}

@media (max-width: 600px) {
  .footer {
    bottom: 43px;
  }
}

@media (max-height: 630px) {
  .footer {
    position: initial;
  }
}
