.container {
  padding: 12px;
}

.required {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin-top: 18px;
}

.input {
  margin-bottom: 6px;
}

.header {
  margin-bottom: 18px;
}

.status {
  display: flex;
}

.fill1 {
  border: 3px solid #243746;
  border-radius: 6px;
  flex: 6;
  z-index: 0;
}

.fill2 {
  border: 3px solid #adb7c0;
  flex: 1;
  margin-left: -2px;
}

.backgroundTwo {
  background-color: #b2bac0 !important;
}

.controls {
  display: flex;
  margin: 20px;
}

.next {
  flex-grow: 2;
  display: flex;
  justify-content: center;
}

.footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.infoSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px 15px;
}

.info {
  color: #000;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
}

.info {
  color: #000;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 400;
}

.emphasis {
  font-style: italic;
}

.normal {
  font-style: normal;
  font-weight: 300;
}

.indent {
  margin-left: 18px;
}

.container ul {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ccInfo {
  color: #000;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 300;
}

.choices {
  border-top: 1px solid #728c9f;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* checkbox */
.containerBox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  appearance: none;
  outline: none;
  border: 1px solid #243746;
  border-radius: 2.5px;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}

.checkbox:checked {
  border: 1px solid #243746;
}

.checkbox:checked + .icon {
  visibility: visible;
}

.icon {
  visibility: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.checkbox:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkbox:disabled + .icon {
  opacity: 0.5;
}

.checkBoxInfo {
  margin-left: 10px;
}

.checkBox {
  flex: 2;
  display: flex;
  justify-content: center;
  font-size: 13px;
}

@media (min-width: 600px) {
  .footer,
  .container {
    max-width: 600px;
    margin: auto;
    right: 0;
    left: 0;
  }
}

@media (max-width: 600px) {
  .footer {
    bottom: 43px;
  }
}

@media (max-height: 630px) {
  .footer {
    position: initial;
  }
}

.link {
  text-decoration: none;
}
