.container {
  padding: 12px;
}

.info {
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.required {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin-top: 10px;
}

.lockIcon {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}

.pci {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  margin-top: 12px;
}

.input {
  margin-bottom: 12px;
}

.header {
  margin-bottom: 18px;
}

.status {
  display: flex;
}

.errorText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  color: white;
  margin-bottom: 5px;
  margin-top: 5px;
  height: 40px;
  width: 100%;
  background-color: red;
  border-radius: 6px;
  font-size: 15px;

}

.error input {
  border: 1px solid #ff0000;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.errorDiv iframe {
  border: 1px solid #ff0000;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 41px;
}

.fill1 {
  border: 3px solid #243746;
  border-radius: 6px;
  flex: 6;
}

.backgroundTwo {
  background-color: #b2bac0 !important;
}

.controls {
  display: flex;
  margin: 20px;
}

.next {
  flex-grow: 2;
  display: flex;
  justify-content: center;
}

.footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.cardTypes {
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 13px;
}

.ccAsterisk {
  display: flex;
  align-items: flex-start;
  height: 100%;
  margin-left: 2px;
}

.btn {
  width: 100%;
  background-color: #adb7c0 !important;
  border-color: #adb7c0 !important;
  margin-top: 12px;
}

.paymentInput {
  display: flex;
  align-items: stretch;
  background-color: #e9ecef;
  border-radius: 6px;
  border: 1px #adb7c0 solid !important;
  height: 41px;
  margin-bottom: 10px;
}

.paymentInputIconContainer {
  padding: 9px 12px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentInputInput {
  flex: auto;
  height: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

@media (min-width: 600px) {
  .footer,
  .container {
    max-width: 600px;
    margin: auto;
    right: 0;
    left: 0;
  }
}

@media (max-width: 600px) {
  .footer {
    bottom: 43px;
  }
}

@media (max-height: 630px) {
  .footer {
    position: initial;
  }
}
