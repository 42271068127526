.container {
  padding: 12px;
}

.required {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin-top: 18px;
}

.homesiteDescription {
  color: #415364;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 300;
}

.input {
  margin-bottom: 6px;
}

.header {
  margin-bottom: 18px;
}

.homesiteSelectHeader {
  margin-top: 18px;
  margin-bottom: 14px;
  color: #243746;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 400;
}

.selectContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selectItem {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.selected {
  width: 10px;
  height: 10px;
  border: 6px solid #243746;
  border-radius: 50%;
  cursor: pointer;
}

.select {
  width: 18px;
  height: 18px;
  border: 2px solid #243746;
  border-radius: 50%;
  cursor: pointer;
}

.selectTextTop {
  color: #415364;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 700;
}

.selectTextBottom {
  color: #415364;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
}

.status {
  display: flex;
}

.errorText {
  font-family: "Open Sans", sans-serif;
  color: #ff0000;
  margin-bottom: 5px;
}

.error input {
  border: 1px solid #ff0000;
}
.error select {
  border: 1px solid #ff0000;
}

.fill1 {
  border: 3px solid #243746;
  border-radius: 6px;
  flex: 4;
  z-index: 2;
}

.fill2 {
  border: 3px solid #adb7c0;
  flex: 3;
  margin-left: -2px;
  border-radius: 6px;
}

.backgroundTwo {
  background-color: #b2bac0 !important;
}

.controls {
  display: flex;
  margin: 20px;
}

.next {
  flex-grow: 2;
  display: flex;
  justify-content: center;
}

.footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

@media (min-width: 600px) {
  .footer,
  .container {
    max-width: 600px;
    margin: auto;
    right: 0;
    left: 0;
  }
}

@media (max-width: 600px) {
  .footer {
    bottom: 43px;
  }
}

@media (max-height: 630px) {
  .footer {
    position: initial;
  }
}
