.button {
  background-color: #243746;
  padding: 0 0.8em;
  border-radius: 4px;
  border: 1px solid #243746;
  color: white;
  font-size: 14px !important;
  line-height: 30px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  cursor: pointer;
  font-family: "Open Sans"
}
