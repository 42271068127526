.container {
  display: flex;
  justify-content: center;
}

.msg {
  font-size: 11px;
  margin: 18px 30px 25px;
  text-align: center;
  max-width: 400px;

  color: #22252e;

  font-size: 11px;

  font-weight: 300;
}

.backgroundOne {
  background-color: #39b54a !important;
}

.header {
  margin-top: 20px;
}

.welcome {
  max-width: 600px;
  margin: auto;
  margin-top: 22px;
  padding: 0px 22px;
}

.link {
  text-decoration: none;
}
