.header {
  display: flex;
  justify-content: center;
  color: var(--Inspiration---91B6BB, #91b6bb);
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 300;
  margin-top: 11px;
  margin-bottom: 8px;
}

.subHeader {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  padding: 0px 55px 0px 55px;
}

.section {
  font-size: 13px;
  padding: 0px 24px 0px 24px;
  max-width: 600px;
  margin: auto;
  text-align: justify;
}

.outerContainer {
  display: flex;
  flex-direction: column;
}

.topContainer {
  max-height: calc(100vh - 55px);
  overflow-y: scroll;
  overflow-x: hidden;

}
.container {
  background-color: rgba(36, 55, 70);
  padding: 1em;
  width: 100%;
  margin: 0 auto;
  font-size: 11px;
  font-weight: lighter;
  color: white;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
}

.btnColor {
  background-color: #39b54a;
  margin-left: 5px;
}

.btnColorDisabled {
  background-color: #adb7c0;
  margin-left: 5px;
  cursor: default;
}

.btnColor2 {
  background-color: #adb7c0;
  margin-right: 5px;
}

.btnGroup {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.infoGroup {
  display: flex;
  justify-content: space-between;
}

.label {
  color: #91b6bb;
}

.link {
  color: #3391ff;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 600px) {
  .topContainer {
    padding-bottom: 43px;
  }
}
