.button {
    background-color: #415364;
    color: white;
    width: auto;
    height: 38px;
    border-radius: 19px;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    font-size: 15px;
}

.title {
    margin: 6px;
    font-family: 'Open Sans', sans-serif;
}

.arrow {
    display: flex;
    justify-content: center;
}

.backgroundOne {
    background-color: #39B54A;
}

.backgroundTwo {
    background-color: #B2BAC0;
}