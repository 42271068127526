.card {
    background-color: #E3EDEE;
    width: 100%;
    height: 191px;
    padding: 12px;
    padding-top: 15px;
}

.header {
    font-size: 18px;
    margin-bottom: 12px;
}

.container {
    background-color: white;
    height: 80px;
    width: auto;
    padding: 11px;
    padding-bottom: 15px;
}

.btnGroup {
    display: flex;
    margin-top: 18px;
    justify-content: space-between;
}

.arrow {
    margin-left: 15px;
    margin-right: auto;
}

.edit {
    background-color: #415364;
}

/* Styles for examples */
.btnColor {
    background-color: #91B6BB !important;
    border-color: #91B6BB !important;
}

.btnColor2 {
    color: black !important;
    background-color: white !important;
    border-color: black !important;
}

.backgroundColor {
    background-color: #F4F4F4;
}